<template>
    <v-container grid-list-xl>
        <UserSelect :value="item.user" filter="experts" @change="item.user = $event" />

        <v-stepper v-model="step" vertical>
            <v-stepper-step step="1" :complete="item.status >= 1" editable> {{ $t("Dados Básicos") }} </v-stepper-step>
            <v-stepper-content step="1">
                <v-select v-model="item.part1" :items="statusform" item-value="id" item-text="name" label="Status" />
                <v-form ref="form1" v-model="valid1" lazy-validation @submit.prevent="validate1">
                    <Basic :item="item" />

                    <v-switch v-model="item.canDouble" :label="$t('Pode dobrar turno de trabalho?')" class="my-0 py-0" />
                    <v-switch v-model="item.canTransport" :label="$t('Pode fazer coleta de pacotes?')" class="my-0 py-0" />
                </v-form>
                <v-btn :loading="lock" :disabled="!valid1" color="success" @click="validate1">{{ $t("Salvar e Continuar") }}</v-btn>
            </v-stepper-content>

            <v-stepper-step step="2" :complete="item.status >= 2" editable> {{ $t("Localidade") }} </v-stepper-step>
            <v-stepper-content step="2">
                <v-form ref="form2" v-model="valid2" lazy-validation @submit.prevent="validate2">
                    <v-flex xs12 mb-12>
                        <v-select v-model="item.part2" :items="statusform" item-value="id" item-text="name" label="Status" />
                    </v-flex>
                    <v-flex xs12>
                        <Address :item="item" />
                    </v-flex>
                </v-form>

                <v-btn :loading="lock" :disabled="!valid2" color="success" @click="validate2">{{ $t("Salvar e Continuar") }}</v-btn>
            </v-stepper-content>

            <v-stepper-step step="3" :complete="item.status >= 3" editable> {{ $t("Dados Adicionais") }} </v-stepper-step>
            <v-stepper-content step="3">
                <v-select v-model="item.part3" :items="statusform" item-value="id" item-text="name" label="Status" />
                <v-form ref="form3" v-model="valid3" lazy-validation @submit.prevent="validate3">
                    <Additional :item="item" />
                </v-form>
                <v-btn :loading="lock" :disabled="!valid3" color="success" @click="validate3">{{ $t("Salvar e Continuar") }}</v-btn>
            </v-stepper-content>

            <v-stepper-step step="4" :complete="item.status >= 4" editable> {{ $t("Meio de transporte") }} </v-stepper-step>
            <v-stepper-content step="4">
                <v-select v-model="item.part4" :items="statusform" item-value="id" item-text="name" label="Status" />
                <v-form ref="form4" v-model="valid4" lazy-validation @submit.prevent="validate4">
                    <Vehicle :item="item" />
                </v-form>

                <v-btn :loading="lock" :disabled="!valid4" color="success" @click="validate4" v-if="hasDeliveryMode">{{ $t("Salvar") }}</v-btn>
                <v-alert icon="error" prominent text dense type="error" v-else> Necessário selecionar pelo menos um forma de trabalho </v-alert>
            </v-stepper-content>

            <v-stepper-step step="5" :complete="item.status >= 5" editable> {{ $t("Aprovação final") }} </v-stepper-step>
            <v-stepper-content step="5">
                <v-layout wrap v-if="item.status == 4 && allFormAccepted">
                    <v-flex xs12>
                        <v-alert outlined type="warning" text>
                            Todo o cadastro foi finalizado e validado, agora clique abaixo para autorizar o especialista a iniciar seu trabalho.
                            <br />
                            <v-btn mt-4 :loading="lock" color="success" @click="authorize">{{ $t("Validar e autorizar especialista") }}</v-btn>
                        </v-alert>
                    </v-flex>
                </v-layout>
                <v-layout wrap v-else-if="item.status == 5 && allFormAccepted">
                    <v-flex xs12>
                        <v-alert outlined type="success" text> Especialista ativo </v-alert>
                    </v-flex>
                </v-layout>
                <v-layout wrap v-else>
                    <v-flex xs12>
                        <v-alert outlined type="error" text> {{ $t("Valide primeiramente os formulários anteriores.") }} </v-alert>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
        </v-stepper>
    </v-container>
</template>

<script>
import { mask } from "vue-the-mask";
import UserSelect from "@/components/shared/UserSelect";
import Address from "@/components/form/Address";
import Basic from "@/pages/Expert/basic";
import Vehicle from "@/pages/Expert/vehicle";
import Additional from "@/pages/Expert/additional";

const itemClean = {
    id: "",
    part1: null,
    part2: null,
    part3: null,
    part4: null,
    status: 0,
    idUser: "",
    name: "",
    cpf: "",
    phone: null,
    cepOk: true,
    cep: "",
    state: "",
    city: "",
    district: "",
    address: "",
    houseNumber: "",
    complement: "",
    birthDate: "",
    stateOfBirth: "",
    motherName: "",
    isMEI: true,
    cnpj: "",
    schooling: "",
    hasMotorcycle: true,
    hasCar: true,
    driverLicense: "",
    driverLicenseIssueDate: "",
    driverLicenseIssueType: "",
    carType: "",
    brand: "",
    model: "",
    plate: "",
    yearOfManufacture: 2021,
    carBrand: "",
    carModel: "",
    carYearOfManufacture: 2021,
    carPlate: "",
    pixType: "key",
    pixKey: "",
    pixBank: "",
    enable: true,
};

export default {
    directives: { mask },

    components: { Address, Basic, Vehicle, Additional, UserSelect },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            valid1: true,
            valid2: true,
            valid3: true,
            valid4: true,
            statusform: [
                { id: 1, name: this.$t("Aprovado") },
                { id: 2, name: this.$t("Reprovado") },
            ],
            item: JSON.parse(JSON.stringify(itemClean)),
            step: 1,
        };
    },

    computed: {
        allFormAccepted() {
            return this.item.part1 == 1 && this.item.part2 == 1 && this.item.part3 == 1 && this.item.part4 == 1;
        },
        hasDeliveryMode() {
            return this.item.isDeliveryByBike || this.item.isDeliveryByMotorcycle || this.item.hasMotorcycle || this.item.isDeliveryByCar || this.item.hasCar;
        }
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                this.clearValidation();

                this.$nextTick(() => {
                    if (newVal == null) {
                        this.item = JSON.parse(JSON.stringify(itemClean));
                    } else {
                        if (newVal.pixType == undefined) {
                            newVal.pixType = "key";
                            newVal.pixKey = "";
                            newVal.pixBank = "";
                        }

                        this.item = { ...newVal };
                    }
                    this.step = this.item && this.item.status < 5 ? this.item.status + 1 : 5;
                });
            },
        },
    },

    methods: {
        validate1() {
            if (this.$refs.form1.validate()) {
                this.save(1);
            }
        },
        validate2() {
            if (this.$refs.form2.validate()) {
                this.save(2);
            }
        },
        validate3() {
            if (this.$refs.form3.validate()) {
                this.save(3);
            }
        },
        validate4() {
            if (this.$refs.form4.validate()) {
                this.save(4);
            }
        },
        authorize() {
            this.save(5);
        },

        save(goToStep) {
            this.lock = true;

            if (this.item.status < goToStep) this.item.status = goToStep;

            if (this.item.id) {
                this.$http
                    .put("experts", this.item.id, this.item)
                    .then(() => {
                        this.$eventHub.$emit("msgSuccess", this.$t("Dados salvo com sucesso."));
                        this.lock = false;
                        if (this.step < 5) this.step++;
                    })
                    .catch((error) => {
                        this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao salvar dados."));
                        this.lock = false;
                    });
            } else {
                this.$http
                    .post("experts", this.item)
                    .then((result) => {
                        this.$eventHub.$emit("msgSuccess", this.$t("Dados salvo com sucesso."));
                        if (result.id) {
                            this.item.id = result.id;
                        }
                        this.lock = false;
                        if (this.step < 5) this.step++;
                    })
                    .catch((error) => {
                        this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao salvar dados."));
                        this.lock = false;
                    });
            }
        },
        clearValidation() {
            if (this.$refs.form1) {
                this.$refs.form1.reset();
            }
            if (this.$refs.form2) {
                this.$refs.form2.reset();
            }
            if (this.$refs.form3) {
                this.$refs.form3.reset();
            }
            if (this.$refs.form4) {
                this.$refs.form4.reset();
            }
        },
    },

    mounted() {
        this.clearValidation();
    },
};
</script>
